<template>
  <div class="feed-options">
    <div class="feed-options-content">
      <a :class="`feed-options-button ${isFeed('list')}`" @click="setQuery({ feed_mode: 'list', post: undefined })">
        <span>{{ $locale["free"] }}</span>
      </a>
      <a :class="`feed-options-button ${isFeed('grid')}`" @click="setQuery({ feed_mode: 'grid', post: undefined })">
        <span>{{ $locale["premium"] }}</span>
      </a>
      <a :class="`feed-options-button ${isFeed('plus')} __plus`" @click="setQuery({ feed_mode: 'plus', post: undefined })">
        <span>{{ $locale["plus"] }}</span>
        <iconic name="mf_lock_flat" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    isFeed: function(mode) {
      if (this.$route.query.feed_mode === mode) {
        return "__mode";
      }

      if (mode === "list" && !this.$route.query.feed_mode) {
        return "__mode";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-options {
  position: relative;
  font-family: Ubuntu Regular;
  &-content {
    display: flex;
    justify-content: space-evenly;
    padding: $mpadding/2 0 0 0;
  }
  &-button {
    display: block;
    padding: $mpadding;
    margin: 0 0 0 0;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: $mpadding/2;
  }
  &-button:hover {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.35) 100%);
  }
  &-button.__mode {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.55) 100%);
    font-family: Ubuntu Bold;
  }
  &-button.__plus {
    font-family: Ubuntu Bold;
  }
}
</style>
